window.addEventListener('heyflow-submit', (event) => {
    window.triggerLead();
});

window.addEventListener('heyflow-init', async (event) => {
    window._udata= {
        n: "AT"
    };
    await setIp();
    zipcodeAutocomplete();
})

async function setIp() {
  const ipInput = document.querySelector('input[data-label="ip"]');
  let requestOptions = { method: 'GET', redirect: 'follow' };

  let res = await fetch("https://europe-southwest1-react-lp-387513.cloudfunctions.net/ip_retain", requestOptions)
  let ip = await res.text()

  ipInput.value = ip;
  document.querySelector('input[data-label="page-url"]').value = window.location.href;
}
/*
function addressAutocomplete() {

    const addressInput = document.querySelector('input[data-label="address"]')
    const zipcodeInput = document.querySelector('input[data-label="zipcode"]')
    const cityInput = document.querySelector('input[data-label="city"]')
    if(!addressInput) return;
  
    let autocomplete;
    function enableAutocomplete() {
      if (!autocomplete) {
        autocomplete = new google.maps.places.Autocomplete(addressInput, {
          componentRestrictions: { country: 'at' },
          fields: ['address_components'],
          types: ['address']
        });
  
        autocomplete.addListener('place_changed', function () {
          const place = autocomplete.getPlace();
          let address = "";
  
          if (place.address_components) {
            place.address_components.forEach(component => {
  
              if (component.types.includes('street_number')) {
                address = `${address} ${component.long_name}`.replace(/\s\s+/g, " ");
              }
  
              if (component.types.includes('route')) {
                address = `${component.long_name} ${address}`.replace(/\s\s+/g, " ");
              }
  
              if (component.types.includes('locality')) {
                cityInput.readOnly = true;
                cityInput.value = component.long_name;
              }
  
              if (component.types.includes('postal_code')) {
                zipcodeInput.readOnly = true;
                zipcodeInput.value = component.long_name;
              }
  
              addressInput.value = address;
            });
          }
        });
      }
    }
  
    function onAddressInput(e) {
      if (e.target.value.length >= 3) {
        // You can adjust the minimum character length as needed
        enableAutocomplete();
      }
    }
  
    addressInput.addEventListener('input', onAddressInput);
  }
  
  function loadGoogleMapsAPI() {
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBiIfWZ0qr6HikZ9cI7kQZueEMt_hYkjo0&libraries=places&callback=addressAutocomplete';
  document.body.appendChild(script);
}
*/
function zipcodeAutocomplete() {

    let zipcodes = [];
    let startZip = "xxxxxx";

    const zipcode_input = document.querySelector("input[data-label='zipcode']");
    const drop_div = document.createElement('div')

    function myFunctionFocusOut(e) {
        if (drop_div.classList.contains("show")) {
            document.getElementById("myDropdown").classList.toggle("show")
            drop_div.style.zIndex = -10
        }
        if(e.relatedTarget && e.relatedTarget.dataset.zipcode) {
            e.target.value = e.relatedTarget.dataset.zipcode;
            e.target.parentNode.parentNode.parentNode.classList.remove("error");
        }
        if (!zipcodes.includes(e.target.value)) {
            zipcode_input.value = ""
        }
    }

    function filterFunction(e) {
        if (e.target.value.length >= 3 && !drop_div.classList.contains("show")) {
            getData()
            drop_div.classList.toggle("show")
            drop_div.style.zIndex = 100
        }
        else if (e.target.value.length < 3 && drop_div.classList.contains("show")) {
            drop_div.classList.toggle("show")
            drop_div.style.zIndex = -10
        }
        else if (e.target.value.length >= 3) {
            getData()
        }
        if(zipcodes.includes(e.target.value) && drop_div.classList.contains("show")) {
            document.getElementById("myDropdown").classList.toggle("show")
            drop_div.style.zIndex = -10
        }
    }

    async function getData() {
        if(!zipcode_input.value.startsWith(startZip)) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
    
            startZip = zipcode_input.value
            
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({
                    "zipcode": startZip,
                    "countryCode": "AT"
                }),
                redirect: 'follow'
            };
    
            let res = await fetch("https://sweepapi.vertigodata.com/verticals_api/zipcode/nfSYfkNH7seSdBuB", requestOptions)
            zipcodes = Object.keys(await res.json())
            zipcode_input.setAttribute("pattern", "(" + zipcodes.join('|') + ")")
        }
    
        let dataArr = zipcodes.filter(zip => zip.startsWith(zipcode_input.value));
    
        cleanData()
        for (const zipcode of dataArr) {
            let a = document.createElement('a');
            a.setAttribute("tabindex", -1)
            a.setAttribute("href", '#')
            a.dataset.zipcode = zipcode;
            a.innerHTML = zipcode;
            drop_div.append(a)
        }
    }
    
    function cleanData() {
        drop_div.innerHTML = ''
    }

    drop_div.setAttribute("id", "myDropdown");
    drop_div.setAttribute("class", "dropdown-content");
    zipcode_input.setAttribute("autocomplete", "off")

    zipcode_input.after(drop_div)

    zipcode_input.addEventListener('focusout', myFunctionFocusOut)

    zipcode_input.addEventListener('keyup', filterFunction)
}